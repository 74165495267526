
import React, { Component } from 'react';
import './Homestyle.css';
import { useState,useEffect } from 'react';
import { useHistory } from 'react-router';
import { BsFillShareFill, BsTelephone } from "react-icons/bs";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import jigsaw from './Assets/jigsaw.png';
import landmarkIcon from './Assets/landmark.png';
import furnitures from './Assets/furnitures.png';
import star from './Assets/star.png';
import { FiMapPin } from "react-icons/fi";
import { Slide } from 'react-slideshow-image';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
	FloatingMenu,
	MainButton,
	ChildButton,
  } from 'react-floating-button-menu';
import { IoClose } from "react-icons/io5";
import { BsPlusLg, BsWhatsapp} from "react-icons/bs";
import { MdClose } from "react-icons/md";
import 'font-awesome/css/font-awesome.min.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useLocation } from "react-router-dom";
import {
	FacebookShareButton,
	FacebookIcon,
	PinterestShareButton,
	PinterestIcon,
	RedditShareButton,
	RedditIcon,
	TelegramShareButton,
	TelegramIcon,
	TwitterShareButton,
	TwitterIcon,
	WhatsappShareButton,
	WhatsappIcon
  } from "react-share";
import Navbar from "../../components/NavBar/index";
 
function Details(props){
	const location = useLocation();
	const[isFABOpen, setFABOpen]=useState(false);
	const[shareModal, setShareModal]=useState(false);
	const[type, setType]=useState([]);
	const[size, setSize]=useState([]);
	const[price, setPrice]=useState([]);
	const[title, setTitle]=useState([]);
	const[city, setCity]=useState([]);
	const[landmark, setLandmark]=useState([]);
	const[furnish, setFurnish]=useState([]);
	const[amenities, setAmenities]=useState([]);
	const[image, setimage] = useState([]); 
	const[item, setItem]=useState([]);
	const[propertyAddress, setPropertyAddress]=useState("");
	const[id, setId]=useState([]);
	const[latitude, setLatitude]=useState("");
	const[longitude, setLongitude]=useState("");
	const[similarAds, setSimilarAds] = useState([]);
	const[mapURL, setMapURL]=useState("");

	const  endpoint = "https://backend.myplotpic.com/uploads/"; 
	const history = useHistory();
	const handleDetails = () => {
		history.push("/ads")
    }
	useEffect(()=>{
	let json = JSON.parse(window.localStorage.getItem("json"));
	const queryParams =  new URLSearchParams(window.location.search);
	let adId = queryParams.get("ad");
	if(adId && adId != undefined){
		let adSplitAr = adId.split("-")
		adId = adSplitAr[0]
		console.log("Via URL ==>")
		var body = {
			adId: adId
		}
		fetch(`https://backend.myplotpic.com/api/getAdByID`, {
			method: "POST",
			body: new URLSearchParams(body),
		})
			.then((response) => response.json())
			.then((responseJson) => {
				console.log(responseJson)
				setSize(responseJson.data[0].areaInCity); 
				setType(responseJson.data[0].typeProperty);
				setPrice(responseJson.data[0].startingPrice);
				setTitle(responseJson.data[0].adTitle);
				setCity(responseJson.data[0].city);
				setLandmark(responseJson.data[0].landmark);
				setFurnish(responseJson.data[0].furnish);
				setAmenities(responseJson.data[0].amenities[0]);
				setItem(responseJson.data[0].files); 
				setId(responseJson.data[0].adId);
				setPropertyAddress(responseJson.data[0].propertyAddress)
				setLatitude(responseJson.data[0].lat)
				setLongitude(responseJson.data[0].lng)

				var body = {
					city: responseJson.data[0].city,
					type: responseJson.data[0].typeProperty,
					posted_by: '',
					landmark: '',
					prize_range: '',
				  };
				fetch(`https://backend.myplotpic.com/api/getFilteredAds`, {
					method: "POST",
					body: new URLSearchParams(body),
				})
					.then((response) => response.json())
					.then((responseJson) => {
						let arr = responseJson.data
						const filteredArr = arr.filter((val) => !val.adId.includes(responseJson.data[0].adId));
						console.log("SIMILAR Ads ===> ", filteredArr);
						setSimilarAds(filteredArr)
					})
					.catch((error) => {
						console.error(error);
					});
		})
	}
	else if(location.state){
		console.log("Via Route ==>", location.state)
		setSize(location.state.areaInCity); 
		setType(location.state.typeProperty);
		setPrice(location.state.startingPrice);
		setTitle(location.state.adTitle);
		setCity(location.state.city);
		setLandmark(location.state.landmark);
		setFurnish(location.state.furnish);
		setAmenities(location.state.amenities[0]);
		setItem(location.state.files); 
		setId(location.state.adId);
		setPropertyAddress(location.state.propertyAddress)
		setLatitude(location.state.lat)
		setLongitude(location.state.lng)

		var body = {
			city: location.state.city,
			type: location.state.typeProperty,
			posted_by: '',
			landmark: '',
			prize_range: '',
		  };
		fetch(`https://backend.myplotpic.com/api/getFilteredAds`, {
			method: "POST",
			body: new URLSearchParams(body),
		})
			.then((response) => response.json())
			.then((responseJson) => {
				let arr = responseJson.data
				const filteredArr = arr.filter((val) => !val.adId.includes(location.state.adId));
				console.log("SIMILAR Ads ===> ", filteredArr);
				setSimilarAds(filteredArr)
			})
			.catch((error) => {
				console.error(error);
			});

	}
	else if(json){
		console.log("Via Ads ==>", json)
		setSize(json.areaInCity); 
		setType(json.typeProperty);
		setPrice(json.startingPrice);
		setTitle(json.adTitle);
		setCity(json.city);
		setLandmark(json.landmark);
		setFurnish(json.furnish);
		setAmenities(json.amenities[0]);
		setItem(json.files); 
		setId(json.adId);
		setPropertyAddress(json.propertyAddress);
		setLatitude(json.lat)
		setLongitude(json.lng)

		var body = {
			city: json.city,
			type: json.typeProperty,
			posted_by: '',
			landmark: '',
			prize_range: '',
		  };
		fetch(`https://backend.myplotpic.com/api/getFilteredAds`, {
			method: "POST",
			body: new URLSearchParams(body),
		})
			.then((response) => response.json())
			.then((responseJson) => {
				let arr = responseJson.data
				const filteredArr = arr.filter((val) => !val.adId.includes(json.adId));
				console.log("SIMILAR Ads ===> ", filteredArr);
				setSimilarAds(filteredArr)
			})
			.catch((error) => {
				console.error(error);
			});
			const mapLoc = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d141.51893669282996!2d${longitude}!3d${latitude}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3750c6ab2ee6db8f%3A0xf2d4f4ad042e4b4!2sNizampur%2C%20Assam%20782509%2C%20India!5e0!3m2!1sen!2sus!4v1647400796205!5m2!1sen!2sus`;
			setMapURL(mapLoc)
	}
	else {
		var path = window.location.href.split('/');
		let url = path[4]
		let temp = url.split("-")
		let temp2 = temp[0]
		console.log("Via URL ==>")
		var body = {
			adId: temp2
		}
		fetch(`https://backend.myplotpic.com/api/getAdByID`, {
			method: "POST",
			body: new URLSearchParams(body),
		})
			.then((response) => response.json())
			.then((responseJson) => {
				console.log(responseJson)
				setSize(responseJson.data[0].areaInCity); 
				setType(responseJson.data[0].typeProperty);
				setPrice(responseJson.data[0].startingPrice);
				setTitle(responseJson.data[0].adTitle);
				setCity(responseJson.data[0].city);
				setLandmark(responseJson.data[0].landmark);
				setFurnish(responseJson.data[0].furnish);
				setAmenities(responseJson.data[0].amenities[0]);
				setItem(responseJson.data[0].files); 
				setId(responseJson.data[0].adId);
				setPropertyAddress(responseJson.data[0].propertyAddress)
				setLatitude(responseJson.data[0].lat)
				setLongitude(responseJson.data[0].lng)

				var body = {
					city: responseJson.data[0].city,
					type: responseJson.data[0].typeProperty,
					posted_by: '',
					landmark: '',
					prize_range: '',
				  };
				fetch(`https://backend.myplotpic.com/api/getFilteredAds`, {
					method: "POST",
					body: new URLSearchParams(body),
				})
					.then((response) => response.json())
					.then((responseJson) => {
						let arr = responseJson.data
						const filteredArr = arr.filter((val) => !val.adId.includes(responseJson.data[0].adId));
						console.log("SIMILAR Ads ===> ", filteredArr);
						setSimilarAds(filteredArr)
					})
					.catch((error) => {
						console.error(error);
					});
		})
	}
	},[]);
	useEffect(() => {
		// 👇️ scroll to top on page load
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
	  }, []);
	const responsive = {
		desktop: {
		  breakpoint: { max: 3000, min: 1024 },
		  items: 3,
		  paritialVisibilityGutter: 0
		},
		tablet: {
		  breakpoint: { max: 1024, min: 464 },
		  items: 1,
		  paritialVisibilityGutter: 0
		},
		mobile: {
		  breakpoint: { max: 464, min: 0 },
		  items: 1,
		  paritialVisibilityGutter: 0
		}
	  };
	return(
		<div>
			<Navbar />
			<div className="fab-btn-hvr">
				<FloatingMenu
				slideSpeed={500}
				direction="up"
				spacing={8}
				isOpen={isFABOpen}
				>
				<MainButton
					iconResting={<BsPlusLg size={22}/>}
					iconActive={<MdClose size={28} className="GrClose-icons"/>}
					style={{backgroundColor:"#00476b"}}
					onClick={() => setFABOpen(!isFABOpen)}
					size={56}
				/>
				<ChildButton
					icon={<BsWhatsapp size={20} className="BsWhatsapp-icons" />}
					style={{backgroundColor:"#00476b"}}
					size={40}
					onClick={() => window.open("https://wa.me/917249556789?text=Hello", '_blank')}
				/>
				</FloatingMenu>
			</div>
			<Modal
				show={shareModal}
				backdrop="static"
				keyboard={false}
				centered
				size="md"
				>
				<ModalBody>
					<div>
					<span className="shareListingHeader">Share</span>
					<IoClose
						size={25}
						className="closeIcon"
						onClick={() => setShareModal(false)}
					/>
					<input
						className="book-input bookinput_"
						type="text"
						value={"https://myplotpic.com/details?ad="+id}
						placeholder="URL"
					/>
					<div style={{marginTop: -15, marginLeft: 4}}>
						<FacebookShareButton url={"https://myplotpic.com/details?ad="+id}>
						<FacebookIcon size={32} round />
						</FacebookShareButton>
						<TwitterShareButton url={"https://myplotpic.com/details?ad="+id}>
						<TwitterIcon size={32} round style={{marginLeft: 5}} />
						</TwitterShareButton>
						<WhatsappShareButton url={"https://myplotpic.com/details?ad="+id}>
						<WhatsappIcon size={32} round style={{marginLeft: 5}} />
						</WhatsappShareButton>
						<PinterestShareButton url={"https://myplotpic.com/details?ad="+id} media={"https://myplotpic.com/details?ad="+id}>
						<PinterestIcon size={32} round style={{marginLeft: 5}} />
						</PinterestShareButton>
						<RedditShareButton url={"https://myplotpic.com/details?ad="+id}>
						<RedditIcon size={32} round style={{marginLeft: 5}} />
						</RedditShareButton>
						<TelegramShareButton url={"https://myplotpic.com/details?ad="+id}>
						<TelegramIcon size={32} round style={{marginLeft: 5}} />
						</TelegramShareButton>
					</div>
					</div>
				</ModalBody>
			</Modal>
			<div className='container minHeightForHover'>
				<div className='row-item-mobile'>
					<div className='container-details-inner'>
						<div className='back1'>
							<p>Ad ID:#{id}</p>
						</div>
					</div>
					<h1 className='text-left style1 title1 mt-4'>{title}</h1> 
					<div className="div1">
						<Row>
							<Col md={1} sm={1} xs={1}>
								<FiMapPin size={24} className="ads-call-icons"/>
							</Col>
							<Col md={9} sm={9} xs={9}>
								<p>{city}</p>
							</Col>
							<Col md={2} sm={2} xs={2}>
								<Row>
									<Col md={6} sm={6} xs={6}>
										<p>4.2</p>
									</Col>
									<Col md={6} sm={6} xs={6}>
										<img src={star} height={18} width={18}></img>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row>
							<Col md={4} sm={4} xs={4}>
							<div className='box-card'>
								<p color='gsGraphite  p1' className='text-titleX'>Type</p>
								<p color='gsGraphite' className='text-subtitle'>{type}</p>
							</div>
							</Col>
							<Col md={4} sm={4} xs={4}>
							<div className ='box-card'>
								<p color='gsGraphite' className='text-titleX'>Size</p>
								<p color='gsGraphite' className='text-subtitle'>{size}</p>
							</div>
							</Col>
							<Col md={4} sm={4} xs={4}>
							<div className ='box-card'>
								<p color ='gsGraphite' className='text-titleX'>Price</p>
								<p color ='gsGraphite' className='text-subtitle'>{price}</p>
							</div>
							</Col>
						</Row>
						<div className='row r2'>
							<button type="button" className="check1 check2 "> Contact </button>
						</div>
					</div>
				</div>
				<Row>
					<Col sm={12} md={7} lg={7}>
						<div className='container-details-inner'>
							<div className='back1 hideForMobile'>
								<p>Ad ID:#{id}</p>
							</div>
							<Slide arrows={false} duration={3200}>
								{item.map((slideImage, index)=> (
									<img className="mb-3 mt-4 img1 sliderDetailsMargin" alt="slider images" src={endpoint+slideImage} />
									) 
								)} 
							</Slide>
							<div className='img3Outer' onClick={() => setShareModal(true)}>
								<BsFillShareFill size={5} className="mb-5 mt-5 mt-3 img3"/>
							</div>
						</div>
						<div className='row ml-3'>
							<h3 className='desc'> Description</h3>
						</div>
						<div className='row text-left'>
							<p className='para1'>{title} in {propertyAddress} is available for sale.</p>
							<p>For more details, click on Contact.</p>
							<br/>
						</div>
						<div className='.pic'>
							{
								longitude === "null" ?
									<></>
								:
									<iframe src={mapURL} width="100%" height="250" allowFullScreen="{false}" loading="lazy" style={{borderRadius: 15, marginBottom: "7%"}} ></iframe>
							}
						</div>
						<h3 className='loc1'>Features</h3>
						<Row>
							<Col md={4} xs={6} sm={6}>
								<div className='cards-about-team'>
									<div className="card-about-team">
									<div className="card-body-about-team">
										<center><img src={furnitures} className="team-imgs"/></center>
									</div>
									<p className="team-text-title">Furnish</p>
									<p className="team-text-subtitle">{furnish}</p>
									</div>
								</div>
							</Col>
							<Col md={4} xs={6} sm={6}>
								<div className='cards-about-team'>
									<div className="card-about-team">
									<div className="card-body-about-team">
										<center><img src={landmarkIcon} className="team-imgs"/></center>
									</div>
									<p className="team-text-title">Landmark</p>
									<p className="team-text-subtitle">{landmark}</p>
									</div>
								</div>
							</Col>
							<Col md={4} xs={12} sm={12}>
								<div className='cards-about-team'>
									<div className="card-about-team">
									<div className="card-body-about-team">
										<center><img src={jigsaw} className="team-imgs"/></center>
									</div>
									<p className="team-text-title">Amenities</p>
									<p className="team-text-subtitle">{amenities}</p>
									</div>
								</div>
							</Col>
						</Row>
						<hr/>
						<div>
							{
								similarAds != [] ? 
								<>
									<h3 className='loc1-similar'>Similar Posts</h3>
									<Carousel
										autoPlay={false}
										autoPlaySpeed={1600}
										ssr
										partialVisible 
										itemClass="image-item"
										responsive={responsive}
									>
										{
											similarAds.map((item, index)=> (
												<div class="container-x" onClick={() => window.open("https://www.myplotpic.com/details?ad="+item.adId)}>
													<div class="card">
														<div class="card__header">
															<img className="similarPostImages" alt="slider images" src={endpoint+item.files[0]}/>
														</div>
														<div class="card__body">
														<span class="tag tag-red">{item.typeProperty}</span>
														<h6>{item.adTitle.slice(0, 70)}..</h6>
														</div>
													</div>
												</div>
											))
										}
									</Carousel>
								</>
								:
								<></>
							}
						</div>
					</Col>
					<Col sm={12} md={5} lg={5}>
						<div className='row-item'>
							<h1 className='text-left style1 title1 mt-4'>{title}</h1> 
							<div className="div1">
								<Row>
									<Col md={1} sm={1} xs={1}>
										<FiMapPin size={24} className="ads-call-icons"/>
									</Col>
									<Col md={9} sm={9} xs={9}>
										<p>{city}</p>
									</Col>
									<Col md={2} sm={2} xs={2}>
										<Row>
											<Col md={6} sm={6} xs={6}>
												<p>4.2</p>
											</Col>
											<Col md={6} sm={6} xs={6}>
												<img src={star} height={18} width={18}></img>
											</Col>
										</Row>
									</Col>
								</Row>
								<Row>
									<Col md={4} sm={4} xs={4}>
									<div className='box-card'>
										<p color='gsGraphite  p1' className='text-titleX'>Type</p>
										<p color='gsGraphite' className='text-subtitle'>{type}</p>
									</div>
									</Col>
									<Col md={4} sm={4} xs={4}>
									<div className ='box-card'>
										<p color='gsGraphite' className='text-titleX'>Size</p>
										<p color='gsGraphite' className='text-subtitle'>{size}</p>
									</div>
									</Col>
									<Col md={4} sm={4} xs={4}>
									<div className ='box-card'>
										<p color ='gsGraphite' className='text-titleX'>Price</p>
										<p color ='gsGraphite' className='text-subtitle'>{price}</p>
									</div>
									</Col>
								</Row>
								<div className='row r2'>
									<button type="button" onClick={() => window.location.href = "tel:+917249556789"} className="check1 check2"> Contact </button>
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</div>
		</div>
	)}

export default Details;