import React from "react";
import { withRouter, Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./create-order.css";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import { IoClose } from "react-icons/io5";
import pay_now from "../../assets/images/pay_now.png";
import pay_later from "../../assets/images/pay_later.png";
import { IoIosArrowForward } from "react-icons/io";
import check from "../../assets/icon/check.png";
import login from "../../assets/login.gif";
import ok from "../../assets/ok.gif";
import qr from "../../assets/qr.png";
import { ToastContainer, toast } from "react-toastify";
import {
  FloatingMenu,
  MainButton,
  ChildButton,
} from 'react-floating-button-menu';
import { BsPlusLg, BsWhatsapp, BsCircle, BsCheckCircleFill } from "react-icons/bs";
import { MdClose } from "react-icons/md";
import Checkbox from '@mui/material/Checkbox';

import Navbar from "../../components/NavBar/index";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../../components/Footer/footer";

const dailOptions = [
    { value: "+91", label: "+91" },
    { value: "+973", label: "+973" },
    { value: "+971", label: "+971" },
    { value: "+974", label: "+971" },
  ];
  
  const priceOptions = [
    { value: "One", label: "1 Visit (₹1800)" },
    { value: "Four", label: "4 Visits (₹6500)" },
    { value: "Twelve", label: "12 Visits (₹18000)" },
  ];
  
  const cityOptions = [
    { value: "Bhubhuneshwar", label: "Bhubhuneshwar" },
    { value: "Chennai", label: "Chennai" },
    { value: "Hydrabad", label: "Hydrabad" },
    { value: "Vizag", label: "Vizag" },
    { value: "Vijaywada", label: "Vijaywada" },
    { value: "Guntur", label: "Guntur" },
    { value: "Amravati", label: "Amravati" },
    { value: "Bengaluru", label: "Bengaluru" },
    { value: "Tirupati", label: "Tirupati" },
  ];

  const styles = {
    row: {
        marginLeft: 0,
        marginRight: 0
    },
    col: {
        paddingLeft: 5,
        paddingRight: 5
    }
  };
  
class create_order extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        userID: "",
        name: "",
        email: "",
        message: null,
        bookingModal: false,
        firstView: true,
        secondView: false,
        paymentView: false,
        visitPackage: null,
        city: null,
        country: "India",
        area: null,
        contactDate: new Date(),
        dialCode: "+91",
        phoneNumber: null,
        totalPrice: 0,
        propertyName: null,
        streetName: null,
        landmark: null,
        isFlat: true,
        isPlot: false,
        droneView: false,
        venueName: null,
        specialInstructions: null,
        isSendWhatsappImages: false,
        isTOC: true,
        packageType: null,
        plotorflat: null,
        errors: {},
        options: [],
        isCheckValided: false,
        isCheck2Valided: false,
        orderComplete: false,
        loginAlertModal: false,
        orderType: "Online",
        password: null,
        phone:null,
        loginModal: false,
        validatePhone: false,
        forgetPasswordModal: false,
        orderID: "",
        isFABOpen: false
        
    };
    //Form1
    this.handleWhatsappPhone = this.handleWhatsappPhone.bind(this);
    this.handleArea = this.handleArea.bind(this);

    //Form2
    this.handlePropertyName = this.handlePropertyName.bind(this);
    this.handleLandmark = this.handleLandmark.bind(this);
    this.handleStreet = this.handleStreet.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.droneView = this.droneView.bind(this);
    this.handleVenue = this.handleVenue.bind(this);
    this.handleSpecialInstructions = this.handleSpecialInstructions.bind(this);
    this.sendWhatsappImages = this.sendWhatsappImages.bind(this);
    this.tocUpdate = this.tocUpdate.bind(this);
    this.nextView = this.nextView.bind(this);
    this.paymentView = this.paymentView.bind(this);
    this.openBooking = this.openBooking.bind(this);
    this.openPayModal = this.openPayModal.bind(this);
    this.createOrder_API = this.createOrder_API.bind(this);

    //LOGIN
    this.handleLoginEmail = this.handleLoginEmail.bind(this);
    this.forgetPasswordModal = this.forgetPasswordModal.bind(this);
    this.nextCheck = this.nextCheck.bind(this);
    this.next2Check = this.next2Check.bind(this);
    this.login = this.login.bind(this);
    this.forgotPassword = this.forgotPassword.bind(this);
    this.toastFunct = this.toastFunct.bind(this);
    this.successToast = this.successToast.bind(this);
    this.errorToast = this.errorToast.bind(this);
    this.OTPErrorToast = this.OTPErrorToast.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handlePhone = this.handlePhone.bind(this);
    this.clearAsyncStorage = this.clearAsyncStorage.bind(this);
  }

  //login
  handleLoginEmail(event) {
    var re = /\S+@\S+\.\S+/;
    var result = re.test(event.target.value);
    let errors = this.state.errors;
    if(re.test(event.target.value))
    {
      this.setState({ errors: errors});
      console.log(result)
    }
    else{
      errors["email"] = "Invalid Email";
      this.setState({ errors: errors });
    }
    this.setState({
      email: event.target.value,
    });
  }
  clearAsyncStorage = async() => {
    localStorage.clear();
    window.location.reload();
  }
  confirmLogoutModal = () => {
    this.clearAsyncStorage();
    this.setState({confirmLogoutModal: false})
  }
  forgetPasswordModal = () => {
    this.setState({forgetPasswordModal: !this.state.forgetPasswordModal})
  }
  toggleButton = () => {
    this.setState({
      sideBar: !this.state.sideBar,
    });
  };
  handlePassword(event) {
    this.setState({
      password: event.target.value
    });
    let errors = this.state.errors;
    var passwordLength = event.target.value.length;
    console.log("LENGTH ====>", passwordLength)
    if (passwordLength > 7) {
      errors["password"] = null;
      this.setState({ errors: errors});
    } else {
      errors["password"] = "Minimum 8 characters";
      this.setState({ errors: errors });
    }
  }
  forgotPassword = async () => {
    this.toastFunct();
      let passwordDetails = {
        email: this.state.email,
      };
      let ld = JSON.stringify(passwordDetails);
      await fetch("https://backend.myplotpic.com/api/forgotPassword", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: ld,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          if (responseJson.message === "Authentication error.") {
            this.errorToast();
          }
          else if(responseJson.error === "unable to send the mail"){
            this.OTPErrorToast();
          }
           else {
            this.successToast();
          }
      });
  }
  openModal() {
    this.setState({ loginModal: true });
  }
  handlePhone(object) {
    if (object.target.value.length <= object.target.maxLength) {
      this.setState({ phone: object.target.value });
    }
    console.log(object.target.value.length)
    let errors = {};
    var phoneLength = object.target.value.length;
    //console.log("LENGTH ====>", phoneLength)
    if (phoneLength === 11 || phoneLength === 10 ) {
      this.setState({ errors: errors, validatePhone: true});
    }
     else {
      errors["phone"] = "Must be 10 digits";
      this.setState({ errors: errors, validatePhone: false });
    }
  }
  toastFunct() {
    toast.info("Loading ..", {
      position: "bottom-center",
      autoClose: 1200,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  successToast() {
    toast.success("Authenticated", {
      position: "bottom-center",
      autoClose: 2700,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  errorToast() {
    toast.error("Authentication Error", {
      position: "bottom-center",
      autoClose: 2700,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  OTPErrorToast() {
    toast.error("Error: Unable to send OTP", {
      position: "bottom-center",
      autoClose: 2700,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  nextCheck = () => {
    const {visitPackage, city, area, phoneNumber, errors} = this.state;
    let packageWarning = this.state.errors["package"];
    let cityWarning = this.state.errors["city"];
    let areaWarning = this.state.errors["area"];
    let phoneWarning = this.state.errors["phone"];
    if(visitPackage != null && city != null && area != null && phoneNumber != null){
      if(packageWarning == null && cityWarning == null && areaWarning == null && phoneWarning == null)
      {
        this.setState({
          isCheckValided: true
        })
      }
      else{
        this.setState({
          isCheckValided: false
        })
      }
    }
    else{
      this.setState({
        isCheckValided: false
      })
    }
  }
  login = async () => {
    this.toastFunct();
      let loginDetails = {
        email: this.state.email,
        password: this.state.password,
        contactno: this.state.phone
      };
      let ld = JSON.stringify(loginDetails);
      console.log("Login Details ====> " + ld);
      await fetch("https://backend.myplotpic.com/api/signin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: ld,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          if (responseJson.message === "Authentication error.") {
            this.errorToast();
          }
          else if(responseJson.message === "Account does not exist"){
            this.errorToast();
          }
           else {
            this.successToast();
            this.storeData(responseJson.user);
            this.storeToken(responseJson.token);
            this.setState({ loginModal: false });
            window.location.reload();
          }
        });
  };
  storeData = async (userData) => {
    localStorage.setItem("userData", JSON.stringify(userData));
    console.log("<=====UserData Saved=====>");
  };
  storeToken = async (token) => {
    localStorage.setItem("token", JSON.stringify(token));
    console.log("<=====Token Saved=====>");
  };
  //LOGIN ENDS

  paymentView() {
    this.setState({ firstView: false, secondView: false, paymentView: true });
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }

  nextView(event) {
    event.preventDefault();
    var userData = localStorage.getItem('userData');
    if(userData){
      this.setState({
        firstView: false,
        secondView: true,
        isCheckValided: false,
      });
    }
    else{
      window.localStorage.setItem("orderVisitPackage", this.state.visitPackage);
      window.localStorage.setItem("orderCity", this.state.city);
      window.localStorage.setItem("orderArea", this.state.area);
      window.localStorage.setItem("orderPhone", this.state.phoneNumber);
      this.setState({loginAlertModal: true});
    }
    console.log("TOTAL AMOUNT ===>", this.state.totalPrice)
  }
  next2Check = () => {
    const {propertyName, streetName, email, errors} = this.state;
    var toc = !this.state.isTOC;
    let propertyWarning = this.state.errors["propertyName"];
    let streetWarning = this.state.errors["streetName"];
    let emailWarning = this.state.errors["email"];
    if(propertyName != null && streetName != null && email != null && toc === true){
      if(propertyWarning == null && streetWarning == null && emailWarning == null)
      {
        this.setState({
          isCheck2Valided: true
        })
      }
      else{
        this.setState({
          isCheck2Valided: false
        })
      }
    }
    else{
      this.setState({
        isCheck2Valided: false
      })
    }
  }
  openBooking() {
    this.setState({ bookingModal: true });
  }
  //HANDLE SUBSCRIBTION PACKAGE 
  handlePackage = (visitPackage) => {
    this.setState({ visitPackage: visitPackage.value });
    if (visitPackage.value === "One") {
      this.setState({ totalPrice: 1800, packageType: "single" });
    }
    if (visitPackage.value === "Four") {
      this.setState({ totalPrice: 6500, packageType: "quaterly" });
    }
    if (visitPackage.value === "Twelve") {
      this.setState({ totalPrice: 18000, packageType: "yearly" });
    }
    let errors = this.state.errors;
    if (this.state.totalPrice > 999 || visitPackage.value) {
      errors["package"] = null
      this.setState({ errors: errors });
    } else {
      errors["package"] = "Select a subscribtion";
      this.setState({ errors: errors });
    }
    this.nextCheck();
  };

  //HANDLE CITY SELECTION
  handleCity = (city) => {
    this.setState({ city: city.value });
    let errors = this.state.errors;
    if (this.state.city != null || city.value) {
      errors["city"] = null
      this.setState({ errors: errors });
    } else {
      errors["city"] = "Select a city";
      this.setState({ errors: errors });
    }
    this.nextCheck();
  };

  //HANDLE AREA & TOWN INPUT
  handleArea = async (event) => {
    let errors = this.state.errors;
    let area = event.target.value.length
    if (area>5) {
      errors["area"] = null
      this.setState({ errors: errors});
    }
     else {
      errors["area"] = "Enter a valid area";
      this.setState({ errors: errors});
    }
    this.setState({
      area: event.target.value,
    });
    this.nextCheck();
  }
  //HANDLE PHONE NUMBER
  handleDail = (dialCode) => {
    this.setState({ dialCode: dialCode.value });
  };
  handleWhatsappPhone = async (object) => {
    if (object.target.value.length <= object.target.maxLength) {
      this.setState({ phoneNumber: object.target.value });
    }
    let errors = this.state.errors;
    var phoneLength = object.target.value.length;
    if (phoneLength === 11 || phoneLength === 10 ) {
      errors["phone"] = null
      this.setState({ errors: errors});
    }
     else {
      errors["phone"] = "Must be 10 digits";
      this.setState({ errors: errors});
    }
    this.nextCheck();
  }

  //HANDLE PROPERTY NAME
  handlePropertyName = (event) => {
    let errors = this.state.errors;
    let propertyName = event.target.value.length
    if (propertyName > 5) {
      errors["propertyName"] = null
      this.setState({ errors: errors});
    }
     else {
      errors["propertyName"] = "Enter a valid property name";
      this.setState({ errors: errors});
    }
    this.setState({
      propertyName: event.target.value,
    });
    this.next2Check();
  };
  handleLandmark = (event) => {
    this.setState({ landmark: event.target.value });
  };

  //HANDLE STREET NAME
  handleStreet = (event) => {
    let errors = this.state.errors;
    let streetName = event.target.value.length
    if (streetName > 5) {
      errors["streetName"] = null
      this.setState({ errors: errors});
    }
     else {
      errors["streetName"] = "Enter a valid street name";
      this.setState({ errors: errors});
    }
    this.setState({
      streetName: event.target.value,
    });
    this.next2Check();
  };
  handleEmail = (event) => {
    var re = /\S+@\S+\.\S+/;
    var result = re.test(event.target.value);
    let errors = this.state.errors;
    if(re.test(event.target.value))
    {
      errors["email"] = null
      this.setState({ errors: errors});
      console.log(result)
    }
    else{
      errors["email"] = "Invalid Email";
      this.setState({ errors: errors });
    }
    this.setState({
      email: event.target.value,
    });
    this.next2Check();
  };
  droneView(e) {
    this.setState({ droneView: e.target.checked });
    if (this.state.droneView === false) {
      this.setState({ totalPrice: this.state.totalPrice + 1000 });
    }
    else{
      this.setState({ totalPrice: this.state.totalPrice - 1000 });
    }
  }
  handleVenue = (event) => {
    this.setState({ venueName: event.target.value });
  };
  handleSpecialInstructions = (event) => {
    this.setState({ specialInstructions: event.target.value });
  };
  sendWhatsappImages(e) {
    this.setState({ isSendWhatsappImages: !e.target.checked });
  }
  tocUpdate(e) {
    this.setState({ isTOC: !e.target.checked });
    var propertyName = this.state.propertyName;
    var streetName = this.state.streetName;
    var email = this.state.email;
    var toc = !this.state.isTOC;
    console.log(toc)
    if(streetName)
    {
      var streetLength = JSON.stringify(streetName.length);
    }
    if(propertyName)
    {
      var propertyNameLength = JSON.stringify(propertyName.length);
    }
    console.log(this.state.area)
    if(propertyNameLength>=4 && streetLength>=4 && e.target.checked)
    {
      this.setState({
        isCheck2Valided: true
      })
    }
    else{
      this.setState({
        isCheck2Valided: false
      })
    }
  }
  componentDidMount = async () => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
    var userData = JSON.parse(localStorage.getItem("userData")) ;
    var orderVisitPackage = window.localStorage.getItem("orderVisitPackage");
    var orderCity = window.localStorage.getItem("orderCity");
    var orderArea = window.localStorage.getItem("orderArea");
    var orderPhone = window.localStorage.getItem("orderPhone");
    var orderStreet= window.localStorage.getItem("orderStreet");
    var orderPropertyPetName = window.localStorage.getItem("orderPropertyName");

    if (orderVisitPackage === "One") {
      this.setState({ totalPrice: 1800, packageType: "single" });
    }
    if (orderVisitPackage === "Four") {
      this.setState({ totalPrice: 6500, packageType: "quaterly" });
    }
    if (orderVisitPackage=== "Twelve") {
      this.setState({ totalPrice: 18000, packageType: "yearly" });
    }
    this.setState({
      visitPackage: orderVisitPackage,
      city: orderCity,
      area: orderArea,
      phoneNumber: orderPhone
    })
    setTimeout( () => {
      this.nextCheck()
    }, 700);
    if(orderStreet){
      this.setState({
        streetName: orderStreet
      })
    }
    if(orderPropertyPetName){
      this.setState({
        propertyName: orderPropertyPetName
      })
    }
    if(userData){
      console.log(userData)
      this.setState({
        email: userData.email,
        name: userData.name
      })
    }
  };

  createOrder = () => {
    this.setState({orderType: "Pay Later"});
    this.createOrder_API();
  }
  createOrder_API = async () => {
    var userID = null;
    var name = null;
    var email = null;
    if(localStorage.getItem("userData")){

      var userData = localStorage.getItem('userData');
      var userDataParse = JSON.parse(userData);

      userID=userDataParse._id;
      name=userDataParse.name;
      email=userDataParse.email;
      console.log("User ID ====> ",userID);

    }
    console.log("Calling Create Order API =====>");
    let orderDetails = {
      userId: userID,
      name: name,
      type: this.state.orderType,
      id_: userID,
      orderAmount: this.state.totalPrice,
      priceOrSubscription: this.state.totalPrice,
      payed: this.state.orderType === "Online" ? "Paid" : "Pending",
      wpContactNo: this.state.phoneNumber,
      status: "Active",
      special_inst: this.state.specialInstructions,
      town: this.state.city,
      area: this.state.area,
      pincode: "500001",
      country: this.state.country,
      customerContactNo: this.state.phoneNumber,
      reachedMobileNo: this.state.phoneNumber,
      contactTiming: this.state.contactDate,
      propertyPetName: this.state.propertyName,
      street: this.state.streetName,
      landmark: this.state.landmark,
      mail_id: this.state.email,
      plotNumber: "53621",
      venture: this.state.venueName,
      send_Images_Confirmation: this.state.isSendWhatsappImages,
      agree_with_terms_Confirmation: true,
      choose_drone_view: this.state.droneView,
      order_type: this.state.packageType,
      plot_or_flat: this.state.plotorflat,
    };
    const token = localStorage.getItem("token");
    this.setState({ token });
    let ld = JSON.stringify(orderDetails);
    await fetch(
      "https://backend.myplotpic.com/api/" + userID + "/createorder",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + JSON.parse(token),
        },
        body: ld,
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.message === "Authentication error.") {
          this.errorToast();
        } else {
          this.setState({
            orderComplete: true,
            orderID: responseJson.orderNo
          })
        }
      });
  };

  openPayModal() {
    var options = {
      key: "rzp_test_1DP5mmOlF5G5ag",
      amount: this.state.totalPrice * 100,
      name: "MyPlotPic",
      image: "https://i.ibb.co/GQ0mrTC/white-Frame.png",
      description: "Plot Visit Request - " + this.state.city,
      handler: (response) => this.createOrder_API(),
      prefill: {
        name: this.state.name,
        email: this.state.email,
        contact: this.state.phoneNumber,
      },
      notes: {
        address: "Pre-Paid Order Booking",
      },
      theme: {
        color: "#d4943f",
      },
    };
    var rzp1 = new window.Razorpay(options);
    rzp1.open();
  }
  closeOrderComplete = () => {
    this.setState({ orderComplete: false });
    this.props.history.push("/")
  }
  render() {
    const { visitPackage, city, dialCode, isFlat, isPlot } = this.state;
    return (
    <div>
      <Navbar />
        <div className="fab-btn-hvr">
            <FloatingMenu
              slideSpeed={500}
              direction="up"
              spacing={8}
              isOpen={this.state.isFABOpen}
            >
              <MainButton
                iconResting={<BsPlusLg size={22}/>}
                iconActive={<MdClose size={28} className="GrClose-icons"/>}
                style={{backgroundColor:"#00476b"}}
                onClick={() => this.setState({ isFABOpen: !this.state.isFABOpen })}
                size={56}
              />
              <ChildButton
                icon={<BsWhatsapp size={20} className="BsWhatsapp-icons" />}
                style={{backgroundColor:"#00476b"}}
                size={40}
                onClick={() => window.open("https://wa.me/917249556789?text=Hello", '_blank')}
              />
            </FloatingMenu>
          </div>
      <div className="bookModal createOrder_bg_mobileMargins">
        <Modal
          show={this.state.loginModal}
          backdrop="static"
          keyboard={false}
          centered
          dialogClassName="border-radius-2"
        >
          <ModalBody>
            <div>
              <IoClose
                size={25}
                className="closeIcon"
                onClick={() => this.setState({ loginModal: false })}
              />
              <center>
                <p
                  className="loginModalTxt"
                  style={{ color: "#02486c", textAlign: "center", marginLeft: 35 }}
                >
                  Login
                </p>
              </center>
              <p className="loginModal_txt">Phone :</p>
              <input
                className="loginInput"
                placeholder="Phone"
                value={this.state.phone}
                type = "number" maxLength = "10"
                onChange={this.handlePhone}
                onFocus={this.handlePhone}
              />
              {
                this.state.errors["phone"] ? (
                  <p id="marginInputs" className="loginErrorTxt">
                    {this.state.errors["phone"]}
                  </p>
                ) : (
                  <></>
                )
              }
              <Row >
                <Col sm={5} xs={5} className="divider-Margin">
                  <hr className="left-hr"/>
                </Col>
                <Col sm={2} xs={2} className="divider-Margin">
                  <center>or</center>
                </Col>
                <Col sm={5} xs={5} className="divider-Margin">
                  <hr className="left-hr" />
                </Col>
              </Row>
              <p className="loginModal_txt">Email :</p>
              <input
                className="loginInput"
                type="text"
                placeholder="Email"
                inputMode="email"
                enterKeyHint="next"
                onChange={this.handleLoginEmail}
                onFocus={this.handleLoginEmail}
              />
              {
                this.state.errors["email"] ? (
                  <p id="marginInputs" className="loginErrorTxt">
                    {this.state.errors["email"]}
                  </p>
                ) : (
                  <></>
                )
              }
              <p className="loginModal_txt">
                Password :
              </p>
              <input
                className="loginInput"
                type="password"
                placeholder="Password"
                onChange={this.handlePassword}
              />
              {
                this.state.errors["password"] ? (
                  <p id="marginInputs" className="loginErrorTxt">
                    {this.state.errors["password"]}
                  </p>
                ) : (
                  <></>
                )
              }
            </div>
            <Row style={{ marginTop: "6%"}}>
              <Col md={1} xs={1} sm={1}>
                <input
                  type="checkbox"
                  onClick={this.rememberMeCheck}
                  checked={!this.state.rememberMeCheck}
                ></input>
              </Col>
              <Col md={4} xs={5} sm={5}>
                <label className="login_txt">
                  Remember me
                </label>
              </Col>
            </Row>
            <center>
              <button
                style={{
                  borderRadius: 60,
                  backgroundColor: "#00476b",
                  marginBottom: "5%",
                  width: 180
                }}
                type="submit"
                onClick={this.login}
              >
                Login
              </button>
            </center>
            <ToastContainer
              position="bottom-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </ModalBody>
        </Modal>
        <Modal
          show={this.state.loginAlertModal}
          backdrop="static"
          keyboard={false}
          dialogClassName="border-radius-2"
          centered
        >
          <ModalBody>
            <div>
              <IoClose
                size={25}
                className="closeIcon"
                onClick={() => this.setState({ loginAlertModal: false })}
              />
              <center>
                <img
                    alt="sample"
                    src={login}
                    style={{height: 300, width: 300, marginBottom: "3%"}}
                />
                <br/>
                <Row>
                  <Col md={6}>
                    <button
                      className="requestQuote"
                      onClick={() => this.setState({ loginAlertModal: false, loginModal: true})}
                    >
                      <p>Login</p>
                    </button>
                  </Col>
                  <Col md={6}>
                    <button
                      className="requestQuote2"
                    >
                      <Link to="/register"><p className="register-modal-btn-txt">Sign Up</p></Link>
                    </button>
                  </Col>
                </Row>      
              </center>
              
            </div>
          </ModalBody>
        </Modal>
        <Modal
          show={this.state.orderComplete}
          backdrop="static"
          keyboard={false}
          fullscreen={true}
        >
          <ModalBody>
            <div>
              <IoClose
                size={25}
                className="closeIcon"
                onClick={this.closeOrderComplete}
              />
              <center>
                <img
                    alt="sample"
                    src={check}
                    className="check-icon"
                />
              </center>
              <p className="orderComplete-txt">Order Placed Sucessfully</p>
              <div className="ok-div">
                <Row>
                  <Col md={8} xs={12} sm={12}>
                    <p className="orderDetails-header">Order Details</p>
                    <Row>
                      <Col md={4} xs={6} sm={6}>
                        <p className="orderDetails-lhs">Order ID</p>
                        <p className="orderDetails-lhs">Town</p>
                        <p className="orderDetails-lhs">Area</p>
                        <p className="orderDetails-lhs">Street</p>
                        {
                          this.state.landmark?
                            <p className="orderDetails-lhs">Landmark</p>
                            :
                            <></>
                        }
                        <p className="orderDetails-lhs">Property Name</p>
                        {
                          this.state.specialInstructions?
                            <p className="orderDetails-lhs">Special Instructions</p>
                            :
                            <></>
                        }
                        <p className="orderDetails-lhs">Total Amount</p>
                        <p className="orderDetails-lhs">Payment Mode</p>
                      </Col>
                      <Col md={8} xs={6} sm={6}>
                        <p className="orderDetails-rhs">{this.state.orderID}</p>
                        <p className="orderDetails-rhs">{this.state.city}</p>
                        <p className="orderDetails-rhs">{this.state.area}</p>
                        <p className="orderDetails-rhs">{this.state.streetName}</p>
                        {
                          this.state.landmark?
                            <p className="orderDetails-rhs">{this.state.landmark}</p>
                            :
                            <></>
                        }
                        <p className="orderDetails-rhs">{this.state.propertyName}</p>
                        {
                          this.state.specialInstructions?
                            <p className="orderDetails-rhs">{this.state.specialInstructions}</p>
                            :
                            <></>
                        }
                        <p className="orderDetails-rhs">Rs. {this.state.totalPrice}</p>
                        <p className="orderDetails-rhs">{this.state.orderType}</p>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={4} xs={12} sm={12}>
                    {
                      this.state.orderType==="Pay Later"?
                        <center>
                          <img
                            alt="sample"
                            src={qr}
                            className="ok-img-qr"
                          />
                      </center>
                      :
                      <img
                        alt="sample"
                        src={ok}
                        className="ok-img"
                      />
                      
                    }
                  </Col>
                </Row>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Row>
          <Col md={7} sm={12} xs={12}>
            <div className="createOrder_bg_mobileNewMargins">
              <Row style={styles.row}>
                <Col sm={6} md={6} lg={6} xs={6} style={styles.col}>
                  <iframe
                    src="https://www.youtube.com/embed/JqqGH0Lj11w"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className="largeVideosOrder"
                    width="100%"
                    loading="lazy"
                  />
                </Col>
                <Col sm={6} md={6} lg={6} xs={6} style={styles.col}>
                  <iframe
                    src="https://www.youtube.com/embed/Tl4EDQj6w4o"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className="largeVideosOrder"
                    width="100%"
                    loading="lazy"
                  />
                </Col>
              </Row>
              <div className="createOrder_bg">
                <div className="createOrder_bg_inner">
                  <p className="createOrder_bg_txt">
                    K.K Construction & Developers Site
                  </p>
                  <p className="createOrder_bg_txt"> 
                    Lankelapalum, Vuda Layout East, West Facings are available
                  </p>
                  <button className="createOrder_bg_btn" onClick={() => window.open("https://virtualvisit.myplotpic.com/share/collection/79kWk?logo=1&info=0&logosize=103&fs=1&vr=1&sd=1&initload=0&autorotate=-1.24&thumbs=4", "_blank")}>Virtual Tour</button>
                </div>
              </div>
            </div>
          </Col>
          {this.state.firstView ? (
            <Col md={5} lg={5} xs={12} sm={12}>
              <form>
                <div className="top verticalLine">
                  <div className="ExtraHeightOnDesktop"></div>
                  <div className="inputs">
                    <Select
                      onFocus={this.handlePackage}
                      onChange={this.handlePackage}
                      onBlur={this.nextCheck}
                      value={priceOptions.find(
                        (item) => item.value === visitPackage
                      )}
                      options={priceOptions}
                      isSearchable={false}
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          height: 45,
                          marginTop: 25,
                        })
                      }}
                      placeholder={
                        <div>Place an Ad INR 1800/- Onwards</div>
                      }
                    />
                    {
                      this.state.errors["package"] ?
                        <span
                          id="marginInputs"
                          className="validateErrorTxt"
                        >
                          {this.state.errors["package"]}
                        </span>
                        :
                        <></>
                    }
                    <Select
                      onFocus={this.handleCity}
                      onChange={this.handleCity}
                      onBlur={this.nextCheck}
                      value={cityOptions.find(
                        (item) => item.value === city
                      )}
                      placeholder={<div>City/Town</div>}
                      options={cityOptions}
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          height: 45,
                          marginTop: 25,
                        })
                      }}
                    />
                    {
                      this.state.errors["city"] ?
                        <span
                          id="marginInputs"
                          className="validateErrorTxt"
                        >
                          {this.state.errors["city"]}
                        </span>
                        :
                        <></>
                    }
                    <input
                        className="createOrderInput"
                        type="text"
                        placeholder="Choose Area"
                        onBlur={this.nextCheck}
                        onChange={(e) => {this.handleArea(e)}}
                        value={this.state.area}
                    />
                    {
                      this.state.errors["area"] ?
                        <span
                          id="marginInputs"
                          className="validateErrorTxt"
                        >
                          {this.state.errors["area"]}
                        </span>
                        :
                        <></>
                    }
                    <input
                      disabled
                      className="createOrderInputDisabled"
                      type="text"
                      placeholder="Country"
                      value={this.state.country}
                    ></input>
                    <Row>
                      <Col md={4} xs={5} sm={5}>
                        <Select
                          onChange={this.handleDail}
                          value={dailOptions.find(
                            (item) => item.value === dialCode
                          )}
                          defaultValue={{ value: "+91", label: "+91" }}
                          options={dailOptions}
                          placeholder={<div>Dial</div>}
                          isSearchable={false}
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              height: 45,
                              marginTop: 28,
                            })
                          }}
                        />
                      </Col>
                      <Col md={8} xs={7} sm={7}>
                        <input
                          className="createOrderInput"
                          type = "number" maxLength = "10"
                          placeholder="Whatsapp Number"
                          value={this.state.phoneNumber}
                          onChange={this.handleWhatsappPhone}
                          onFocus={this.handleWhatsappPhone}
                          onBlur={this.nextCheck}
                        ></input>
                        {this.state.errors["phone"] ? (
                          <span
                            id="marginInputs"
                            className="validateErrorTxt"
                          >
                            {this.state.errors["phone"]}
                          </span>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                  </div>
                  <center>
                    {this.state.isCheckValided ? (
                      <button
                        style={{
                          borderRadius: 6,
                          width: 140,
                          height: 45,
                          fontSize: 17,
                          marginTop:"7%",
                          marginBottom: "3%",
                          backgroundColor: "#00AEEC",
                        }}
                        onClick={this.nextView}
                      >
                        Next
                      </button>
                    ) : (
                      <button
                        disabled
                        style={{
                          borderRadius: 6,
                          marginTop:"7%",
                          width: 140,
                          height: 45,
                          fontSize: 17,
                          marginBottom: "3%",
                          backgroundColor: "#E1E1E1",
                          color: "#222"
                        }}
                      >
                        Next
                      </button>
                    )}
                  </center>
                </div>
              </form>
            </Col>
          ) : (
            <></>
          )}
          {this.state.secondView ? (
            <Col md={5} lg={5}>
              <form>
                <div className="top verticalLine">
                  <div className="ExtraHeightOnDesktop"></div>
                  <div className="inputs">
                    <input
                        className="createOrderInputFirst"
                        type="text"
                        value={this.state.propertyName}
                        onBlur={this.next2Check}
                        onChange={this.handlePropertyName}
                        placeholder="Property/Project Name*"
                      />
                      {
                        this.state.errors["propertyName"] ?
                          <span
                            id="marginInputs"
                            className="validateErrorTxt"
                          >
                            {this.state.errors["propertyName"]}
                          </span>
                        :
                          <></>
                      }
                      <input
                        className="createOrderInput"
                        type="text"
                        value={this.state.landmark}
                        placeholder="Landmark"
                        onChange={this.handleLandmark}
                      />
                      <input
                        className="createOrderInput"
                        type="text"
                        value={this.state.streetName}
                        placeholder="Street*"
                        onBlur={this.next2Check}
                        onChange={this.handleStreet}
                      />
                      {
                        this.state.errors["streetName"] ?
                          <span
                            id="marginInputs"
                            className="validateErrorTxt"
                          >
                            {this.state.errors["streetName"]}
                          </span>
                        :
                          <></>
                      }
                      <input
                        className="createOrderInput"
                        type="text"
                        value={this.state.email}
                        placeholder="Email ID*"
                        onBlur={this.next2Check}
                        onChange={this.handleEmail}
                      />
                      {
                        this.state.errors["email"] ?
                          <span
                            className="validateErrorTxt"
                          >
                            {this.state.errors["email"]}
                          </span>
                        :
                          <></>
                      }              
                      <Row style={{marginTop: "4%",}}>
                        <Col md={4} xs={5} sm={5}>
                            <>
                                <Checkbox
                                    checked={isFlat}
                                    onChange={() => this.setState({isFlat: true, isPlot: false})}
                                    icon={<BsCircle size={20} className="chbk-icons"/>}
                                    checkedIcon={<BsCheckCircleFill size={20} className="chbk-icons"/>}
                                />
                                <span className="newDemo-radio-txt">Flat</span>
                            </>
                        </Col>
                        <Col md={4} xs={5} sm={5}>
                            <>
                                <Checkbox
                                    checked={isPlot}
                                    onChange={() => this.setState({isPlot: true, isFlat: false})}
                                    icon={<BsCircle size={20} className="chbk-icons"/>}
                                    checkedIcon={<BsCheckCircleFill size={20} className="chbk-icons"/>}
                                />
                                <span className="newDemo-radio-txt">Plot</span>
                            </>
                        </Col>
                      </Row>
                      <Row style={{marginTop: "4%",}}>
                        <Col md={12} xs={12} sm={12}>
                            <>
                                <Checkbox
                                    checked={this.state.droneView}
                                    onChange={this.droneView}
                                    icon={<BsCircle size={20} className="chbk-icons"/>}
                                    checkedIcon={<BsCheckCircleFill size={20} className="chbk-icons"/>}
                                />
                                <span className="newDemo-radio-txt">Choose Drone View (INR 1000/- Extra)</span>
                            </>
                        </Col>
                      </Row>
                      <input
                        className="createOrderInput"
                        type="text"
                        onChange={this.handleVenue}
                        placeholder="Venue or Association Name"
                      />
                      <textarea 
                        className="createOrderMessage"
                        type="text"
                        onChange={this.handleSpecialInstructions}
                        placeholder="Special Instructions"
                      />
                      <Row style={{marginTop: "4%",}}>
                        <Col md={12} xs={12} sm={12}>
                            <>
                                <Checkbox
                                    checked={!this.state.isSendWhatsappImages}
                                    onChange={this.sendWhatsappImages}
                                    icon={<BsCircle size={20} className="chbk-icons"/>}
                                    checkedIcon={<BsCheckCircleFill size={20} className="chbk-icons"/>}
                                />
                                <span className="newDemo-radio-txt">Send Images & Videos on Whatsapp</span>
                            </>
                        </Col>
                      </Row>
                      <Row style={{marginTop: "4%",}}>
                        <Col md={12} xs={12} sm={12}>
                            <>
                                <Checkbox
                                    checked={!this.state.isTOC}
                                    onChange={this.tocUpdate}
                                    icon={<BsCircle size={20} className="chbk-icons"/>}
                                    checkedIcon={<BsCheckCircleFill size={20} className="chbk-icons"/>}
                                />
                                <span className="newDemo-radio-txt">I Agree with <b>Terms and Conditions</b></span>
                            </>
                        </Col>
                      </Row>
                    </div>
                    <center>
                      {this.state.isCheck2Valided ? (
                        <button
                        style={{
                          borderRadius: 6,
                          height: 45,
                          fontSize: 17,
                          marginTop:"7%",
                          marginBottom: "3%",
                          backgroundColor: "#00AEEC",
                        }}
                          onClick={this.paymentView}
                        >
                          Proceed For Payment
                        </button>
                      ) : (
                        <button
                          style={{
                            borderRadius: 6,
                            marginTop:"7%",
                            height: 45,
                            fontSize: 17,
                            marginBottom: "3%",
                            backgroundColor: "#E1E1E1",
                            color: "#222"
                          }}
                          disabled
                        >
                          Proceed For Payment
                        </button>
                      )}
                    </center>
                </div>
              </form>
            </Col>
          ) : (
            <></>
          )}
          {this.state.paymentView ? (
              <Col md={5} lg={5}>
              <div className="txt_col top verticalLine">
                <div className="ExtraHeightOnDesktop"></div>
                <div className="inputs">
                  <p className="choosePayment">Mode of Payment</p>
                  <div className="paymentView">
                    <Row>
                      <Col md={2} xs={2} sm={2}>
                        <img
                          alt="sample"
                          src={pay_later}
                          className="paymentIcon"
                        />
                      </Col>
                      <Col md={6} xs={6} sm={6}>
                        <p
                          className="payment-txt"
                          onClick={this.createOrder}
                        >
                          Pay Later
                        </p>
                      </Col>
                      <Col md={4} xs={4} sm={4}>
                        <IoIosArrowForward size={21} className="nextIcon" />
                      </Col>
                    </Row>
                  </div>
                  <div
                    className="paymentView payment-view-gap"
                    onClick={this.openPayModal}
                  >
                    <Row>
                      <Col md={2} xs={2} sm={2}>
                        <img
                          alt="sample"
                          src={pay_now}
                          className="paymentIcon"
                        />
                      </Col>
                      <Col md={6} xs={8} sm={8}>
                        <p className="payment-txt">Pay Now</p>
                      </Col>
                      <Col md={4} xs={2} sm={2}>
                        <IoIosArrowForward size={21} className="nextIconNew" />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </Col>
          ) : (
            <></>
          )}
        </Row>
      </div>
      <Footer/>
    </div>
    );
  }
}

export default withRouter(create_order);
