import React from "react";
import './Update.css';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Footer from "../../components/Footer/footer";
import { BsFillCircleFill } from "react-icons/bs";
import axios from "axios";
import Navbar from "../../components/NavBar/index";

class Update extends React.Component{
    constructor(props)
    {
        super(props); 
        this.state = {
            data : {},
            area:null,
            town: null,
            street: null,
            landmark: null,
            customerContactNo: null,
            propertyPetName: null,
            plotNumber: null,
            venture: null,
            special_inst: null,
            pincode: null,
            mail: null,
            orderNumber: null

        }
        this.getData = this.getData.bind(this);
        this.postData = this.postData.bind(this);
        this.handleArea = this.handleArea.bind(this);
        this.handleTown = this.handleTown.bind(this);
        this.handleStreet = this.handleStreet.bind(this);
        this.handleLandmark = this.handleLandmark.bind(this);
        this.handleContactNo = this.handleContactNo.bind(this);
        this.handlePropertyName = this.handlePropertyName.bind(this);
        this.handlePlotNumber = this.handlePlotNumber.bind(this);
        this.handleVenture = this.handleVenture.bind(this);
        this.handleInstructions = this.handleInstructions.bind(this);
        this.handlePincode = this.handlePincode.bind(this);
    }
    handleArea(event) {
        this.setState({ area: event.target.value });
    }
    handleTown(event) {
        this.setState({ town: event.target.value });
    }
    handleStreet(event) {
        this.setState({ street: event.target.value });
    }
    handleLandmark(event) {
        this.setState({ landmark: event.target.value });
    }
    handleContactNo(event) {
        this.setState({ customerContactNo: event.target.value });
    }
    handlePropertyName(event) {
        this.setState({ propertyPetName: event.target.value });
    }
    handlePlotNumber(event) {
        this.setState({ plotNumber: event.target.value });
    }
    handleVenture(event) {
        this.setState({ venture: event.target.value });
    }
    handleInstructions(event) {
        this.setState({ special_inst: event.target.value });
    }
    handlePincode(event) {
        this.setState({ pincode: event.target.value });
    }
    getData = () => {
        const dataVar = JSON.parse(window.localStorage.getItem('orderUpdate'));
        this.state.data = dataVar;
        this.setState({
            orderNumber: dataVar.orderNo,
            town: dataVar.town,
            street: dataVar.street,
            area: dataVar.area,
            propertyPetName: dataVar.propertyPetName,
            plotNumber: dataVar.plotNumber,
            venture: dataVar.venture,
            customerContactNo: dataVar.customerContactNo,
            pincode: dataVar.pincode,
            mail: dataVar.mail_id
        })
        console.log("ORDER Details: ", this.state.data)
    }
    postData = () => {
        var token = window.localStorage.getItem("token");
        var stripToken = token.replaceAll('"', '');
        var userData = JSON.parse(window.localStorage.getItem("userData"));
        var id = userData._id;
        console.log("TOKEN ===>", stripToken);
        let dataPost = {
            files: [
              {
                filename: "",
              },
            ],
            town: this.state.town,
            area: this.state.area,
            pincode: this.state.pincode,
            country: "India",
            customerContactNo: this.state.customerContactNo,
            propertyPetName: this.state.propertyPetName,
            street: this.state.street,
            landmark: this.state.landmark,
            plotNumber: this.state.plotNumber,
            venture: this.state.venture,
            whatsapp_no:  this.state.customerContactNo
        };
        var OrderID=this.state.orderNumber;

        console.log("DATA ===>", dataPost)
        var api ="https://backend.myplotpic.com/api/" + id + "/" + OrderID + "/update"
        axios.put(api, dataPost, { headers: { "Authorization": `Bearer ${stripToken}` } })
          .then(res => {
            console.log(res.data);
        }).catch((error) => {
                console.error(error);
        });
      }
    componentDidMount = async () => {
        this.getData();
      };
    render(){
    return(
        <div>
            <Navbar />
            <div className="container-status">
                <div className="divTitleBoxAlign">
                    <p className="text_Title-ads">Update Order Details</p>
                </div>
                <div className="cards-status">
                    <div className="cards-status-inner-active">
                        <p className="title-content">Order ID #{this.state.data.orderNo}</p>
                    </div>
                    <Row>
                        <Col md={6}>
                            <div className="update-left-div">
                                <input
                                    className="update-input"
                                    type="text"
                                    placeholder={"Street"}
                                    value={this.state.street}
                                    onChange={this.handleStreet}
                                />
                                <input
                                    className="update-input"
                                    type="text"
                                    placeholder={"Area"}
                                    value={this.state.area}
                                    onChange={this.handleArea}
                                />
                                <input
                                    className="update-input"
                                    type="text"
                                    placeholder={"Landmark"}
                                    value={this.state.landmark}
                                    onChange={this.handleLandmark}
                                />
                                <input
                                    className="update-input"
                                    type="text"
                                    placeholder={"Town"}
                                    value={this.state.town}
                                    onChange={this.handleArea}
                                />
                                <input
                                    className="update-input-disabled"
                                    type="tel"
                                    placeholder={"Pincode"}
                                    value={this.state.pincode}
                                    onChange={this.handlePincode}
                                    disabled
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="update-right-div">
                                <input
                                    className="update-input"
                                    type="text"
                                    placeholder={"Property Name"}
                                    value={this.state.propertyPetName}
                                    onChange={this.handlePropertyName}
                                />
                                <input
                                    className="update-input"
                                    type="text"
                                    placeholder={"Plot"}
                                    value={this.state.plotNumber}
                                    onChange={this.handlePlotNumber}
                                />
                                <input
                                    className="update-input"
                                    type="text"
                                    placeholder={"Venture"}
                                    value={this.state.venture}
                                    onChange={this.handleVenture}
                                />
                                <input
                                    className="update-input-disabled"
                                    type="text"
                                    placeholder={"Email"}
                                    value={this.state.mail}
                                    disabled
                                />
                                <input
                                    className="update-input"
                                    type="tel"
                                    placeholder={"Contact Number"}
                                    value={this.state.customerContactNo}
                                    onChange={this.handleContactNo}
                                />
                            </div>
                        </Col>
                    </Row>
                    <center>
                        <button
                            className="requestSubmitEditOrder"
                            onClick= {this.postData}
                        >
                        <p>Submit</p>
                      </button>
                    </center>
                    
                </div>
            </div>
            <Footer/>
        </div>
    )
  }
}
export default Update;