import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

//PAGES

//import home from "./pages/home/home";
import about_us from "./pages/about-us/about_us";
import contact from "./pages/contact/contact";
//import ads from "./pages/ads/ads";
import services from "./pages/services/services";
import register from "./pages/register/register";
import terms_of_use from "./pages/terms-and-policy/terms-and-conditions/terms-of-use";
import privacy_policy from "./pages/terms-and-policy/privacy-policy/privacy-policy";
import refund_policy from "./pages/terms-and-policy/refund-policy/refund-policy";
import verify_email from "./pages/verify-email/verify-email";
import verify_phone from "./pages/verify-phone/verify-phone";
import create_order from "./pages/create-order/create-order";
import Details from "./pages/details/Details";
import Update from "./pages/update-order/Update";
import notifications from "./pages/notifications/notifications";
import Status from "./pages/order-status/Status";
import Login from "./pages/login/login";
import Connect from "./pages/connect/Connect";
import ads from "./pages/ads/ads";
import ExclusiveProjects from "./pages/ads/exclusiveProjects";
import home from "./pages/home/home";
import MapComponent from "./pages/map-component/mapComponent";

class App extends Component {
  render() {
    return (
      <Router>
        
        <Switch>
          <Route exact path="/" component={home} /> 
          <Route path="/about-us" component={about_us} />
          <Route path="/contact" component={contact} />
          <Route path="/services" component={services} />
          <Route path="/register" component={register} />
          <Route path="/terms-of-use" component={terms_of_use} />
          <Route path="/privacy-policy" component={privacy_policy} />
          <Route path="/refund-policy" component={refund_policy} />
          <Route path="/verify-email" component={verify_email} />
          <Route path="/verify-phone" component={verify_phone} />
          <Route path="/create-order" component={create_order} />
          <Route path="/flat/" component={Details}/>
          <Route path="/plot/" component={Details}/>
          <Route path="/status" component={Status}/>
          <Route path="/update" component={Update}/>
          <Route path="/connect" component={Connect}/>
          <Route path="/notifications" component={notifications}/>
          <Route path="/login" component={Login}/>
          <Route path ="/ads" component={ads}/>
          <Route path ="/ExclusiveProjects" component={ExclusiveProjects}/>
          <Route path ="/map" component={MapComponent}/>
        </Switch>
      </Router>
    );
  }
}

export default App;
