import React from "react";
import { withRouter } from "react-router";
import "./HomePageCard.css";

class HomePageCard extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    var endpoint = "https://backend.myplotpic.com/uploads/";
    var slideImage = this.props.data.files[0];
    console.log("DATA HERE => ", this.props.data)
    return (
      <div className="cards-box-ads-homepage">
        <img
          className="ads-img-slider-homestyle"
          alt="slider images"
          src={endpoint + slideImage}
        />
        <div className="wrap-searchCard">
          
          <a href={"https://www.myplotpic.com/" + this.props.data.typeProperty + "/" + this.props.data.adId}><p className="ad-title-homepage">{this.props.data.adTitle}</p></a>
        </div>
      </div>
    );
  }
}
export default withRouter(HomePageCard);
