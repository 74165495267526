import React from "react";
import { withRouter } from "react-router-dom";
import "./notifications.css";
import Footer from "../../components/Footer/footer";
import axios from "axios";
import { AiOutlineBell } from "react-icons/ai";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  FloatingMenu,
  MainButton,
  ChildButton,
} from 'react-floating-button-menu';
import { BsPlusLg, BsWhatsapp} from "react-icons/bs";
import { MdClose } from "react-icons/md";
import Navbar from "../../components/NavBar/index";
import no_notif from "../../assets/404error.png";

class notifications extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notificationData: [],
            data : {},
            isFABOpen: false
        };
        this.getData = this.getData.bind(this);
      }
      getData = async() => {
        var token = window.localStorage.getItem("token");
        var stripToken = token.replaceAll('"', '');
        console.log("token ==>", stripToken)
        var userData = JSON.parse(window.localStorage.getItem("userData"));
        var id = userData._id;
        var api =" https://backend.myplotpic.com/api/" + id + "/myAppNotification"
        await axios.get(api, { headers: { "Authorization": `Bearer ${stripToken}` } })
          .then(res => {
            if(res.data != "no such notification found"){
              this.setState({
                notificationData: res.data
              })
            }
            console.log("RESPONSE DATA ======>", res.data);
          })
          .catch((error) => {
             console.error(error);
          });
      }
      componentDidMount() {
        this.getData();
      };
  render() {
    return (
    <div>
      <Navbar/>
      <div className="fab-btn-hvr">
        <FloatingMenu
          slideSpeed={500}
          direction="up"
          spacing={8}
          isOpen={this.state.isFABOpen}
        >
          <MainButton
            iconResting={<BsPlusLg size={22}/>}
            iconActive={<MdClose size={28} className="GrClose-icons"/>}
            style={{backgroundColor:"#00476b"}}
            onClick={() => this.setState({ isFABOpen: !this.state.isFABOpen })}
            size={56}
          />
          <ChildButton
            icon={<BsWhatsapp size={20} className="BsWhatsapp-icons" />}
            style={{backgroundColor:"#00476b"}}
            size={40}
            onClick={() => window.open("https://wa.me/917249556789?text=Hello", '_blank')}
          />
        </FloatingMenu>
      </div>
      <div className="container-status notif-margin">
        <div className="new-bx-ads status-search">
            <p className="text_Title-notif">Recent Notifications</p>
        </div>
        {
          this.state.notificationData.length !=0 ?
            <>
              {
                  this.state.notificationData.map((item, index) => (
                      <div key={index} className="notification-div">
                        <Row>
                          <Col md={1} xs={1} sm={1}>
                            <AiOutlineBell size={22} className="notif-active" />
                          </Col>
                          <Col md={11} xs={11} sm={11}>
                          <p className="notification-txt">{item.message}</p>
                          </Col>
                        </Row>
                      </div>
                  ))
              }
            </>
          :
            <center>
              <img src={no_notif} style={{ height: 400, width: 400 }}/>
            </center>
        }
      </div>
      <Footer/>
    </div>
    );
  }
}

export default withRouter(notifications);
