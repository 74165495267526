import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import { Slide } from "react-slideshow-image";
import { BsFillShareFill, BsTelephone } from "react-icons/bs";
import { FiMapPin } from "react-icons/fi";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import "react-slideshow-image/dist/styles.css";
import {
  FacebookShareButton,
  FacebookIcon,
  PinterestShareButton,
  PinterestIcon,
  RedditShareButton,
  RedditIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon
} from "react-share";
import { IoClose } from "react-icons/io5";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { withRouter } from "react-router";
import "../../pages/ads/ads.css";
import verified from "../../assets/verified.png";
import location from "../../assets/location.png";
import money from "../../assets/money.png";
import area from "../../assets/area.png";

class AdsCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shareModal: false,
      currentUrl: null
    }
    this.handleDetails = this.handleDetails.bind(this);
    this.openLocation = this.openLocation.bind(this);
    this.brochure = this.brochure.bind(this);
    this.handleVirtual = this.handleVirtual.bind(this);
    this.shareModal = this.shareModal.bind(this);
  }
  brochure = (fileName) => {
    window.open("https://backend.myplotpic.com/uploads/" + fileName);
  };
  handleDetails = (item) => {
    window.localStorage.clear();
    window.localStorage.setItem("json", JSON.stringify(item));
    this.props.history.push("/" + item.typeProperty);
  };
  openLocation(lat, long) {
    const geocoords = long + "," + lat;
    window.open("https://www.google.com/maps?q=" + geocoords);
  }
  handleVirtual = (url) => {
    window.open(url);
  };
  shareModal = (adId) => {
    this.setState({
      shareModal: true,
      currentUrl: "https://myplotpic.com/details?ad=" + adId
    })
  };
  render() {
    var endpoint = "https://backend.myplotpic.com/uploads/";
    return (
      <div className="cards-ads" key={this.props.key}>
        <Modal
          show={this.state.shareModal}
          backdrop="static"
          keyboard={false}
          centered
          size="md"
        >
          <ModalBody>
            <div>
              <span className="shareListingHeader">Share</span>
              <IoClose
                size={25}
                className="closeIcon"
                onClick={() => this.setState({ shareModal: false, currentUrl: null })}
              />
              <input
                className="book-input bookinput_"
                type="text"
                value={this.state.currentUrl}
                placeholder="URL"
              />
              <div style={{marginTop: -15, marginLeft: 4}}>
                <FacebookShareButton url={this.state.currentUrl}>
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <TwitterShareButton url={this.state.currentUrl}>
                  <TwitterIcon size={32} round style={{marginLeft: 5}} />
                </TwitterShareButton>
                <WhatsappShareButton url={this.state.currentUrl}>
                  <WhatsappIcon size={32} round style={{marginLeft: 5}} />
                </WhatsappShareButton>
                <PinterestShareButton url={this.state.currentUrl} media={this.state.currentUrl}>
                  <PinterestIcon size={32} round style={{marginLeft: 5}} />
                </PinterestShareButton>
                <RedditShareButton url={this.state.currentUrl}>
                  <RedditIcon size={32} round style={{marginLeft: 5}} />
                </RedditShareButton>
                <TelegramShareButton url={this.state.currentUrl}>
                  <TelegramIcon size={32} round style={{marginLeft: 5}} />
                </TelegramShareButton>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <div className="new-banner">
          <div id="leftCol2">
            <center>
              <img
                alt="logo"
                src={verified}
                className="verified_icon"
              />
            </center>
          </div>
        </div>
        <div className="cards-box-ads">
        <Row>
            <div className="card-ads-topDiv">
              <Row>
                <Col md={4}>
                  <p>Listing ID: #{this.props.data.adId}</p>
                  <p className="ad-title" onClick={() => this.handleDetails(this.props.data)}>
                    {this.props.data.adTitle}
                  </p>
                  <Row>
                    <Col md={1}>
                      <img className="ads_info_icon" src={location} />
                    </Col>
                    <Col md={10} style={{marginLeft: 7}}>
                      <span className="pp1Txt" id="price-style">
                        {this.props.data.propertyAddress}
                      </span>
                    </Col>
                  </Row>
                  <Row style={{marginTop: "1.5%"}}>
                    <Col md={1}>
                      <img className="ads_info_icon" src={money} />
                    </Col>
                    <Col md={10} style={{marginLeft: 7}}>
                      <span className="pp1Txt" id="price-style">
                        ₹{this.props.data.startingPrice}
                      </span>
                    </Col>
                  </Row>
                  <Row style={{marginTop: "1.5%", marginBottom:"3%"}}>
                    <Col md={1}>
                      <img className="ads_info_icon" src={area} />
                    </Col>
                    <Col md={10} style={{marginLeft: 7, marginTop: 4}}>
                      <span className="pp1Txt" id="price-style">
                        {this.props.data.areaInCity}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    {
                      this.props.data.viewVirtualLink ?
                      <Col md={5} sm={12} xs={12}>
                        <button
                          className="brocher-btn-virtual"
                          onClick={() =>
                            this.handleVirtual(this.props.data.viewVirtualLink)
                          }
                        >
                          Virtual Tour
                        </button>
                      </Col>
                      :
                      <></>
                    }
                    {
                      this.props.data.brochure ?
                        <>
                        {
                          this.props.data.brochure === "https://backend.myplotpic.com/uploads/https://backend.myplotpic.com/uploads/https://backend.myplotpic.com/uploads/" ?
                            <></>
                            :
                            <Col md={5} xs={12} sm={12}>
                              <button 
                                className="brocher-btn-virtual"
                                onClick={() => this.brochure(this.props.data.brochure)}>
                                  Brochure
                              </button>
                            </Col>
                        }
                        </>
                      :
                        <></>
                    }
                    <div className="new-Ads-icons">
                      <Row>
                        <Col className="pe-4" md={2}>
                          <a
                            href={"tel:" + "+91" + this.props.data.contactNumber}
                          >
                            <BsTelephone size={22} className="ads-call-icons" />
                          </a>
                        </Col>
                        <Col md={2} className="pe-4">
                          <BsFillShareFill size={20} className="ads-call-icons" onClick={() => this.shareModal(this.props.data.adId)} />
                        </Col>
                        {
                          this.props.data.lat == "null" || this.props.data.lat == undefined ?
                            <></>
                          :
                            <Col className="pe-4" md={2}>
                              <FiMapPin
                                size={22}
                                className="ads-call-icons"
                                onClick={() =>
                                  this.openLocation(
                                    this.props.data.lat,
                                    this.props.data.lng
                                  )
                                }
                              />
                            </Col>
                        }
                        <Col md={2}>
                          <BsFillInfoCircleFill
                              size={25}
                              className="ads-call-icons"
                              onClick={() => this.handleDetails(this.props.data)}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Row>
                </Col>
                <Col md={4} >
                  <div className="ads-img-slider-div">
                    <Slide arrows={false} duration={3000}>
                      {this.props.data.files.map((slideImage, index) => (
                        <img
                          className="ads-img-slider"
                          alt="slider images"
                          src={endpoint + slideImage}
                        />
                      ))}
                    </Slide>
                  </div>
                </Col>
                <Col md={4}>
                  <LazyLoadComponent>
                    <iframe
                      src={this.props.data.youtubeLink[0]}
                      title="YouTube video player"
                      frameBorder="0"
                      className="adVideo"
                    ></iframe>
                  </LazyLoadComponent>
                </Col>
              </Row>
            </div>
          </Row>
        </div>
        <div className="cards-box-ads-mobile">
          <center>
            <p className="ad-title-mobile">{this.props.data.adTitle}</p>
          </center>
          <Slide arrows={false} duration={3000}>
            {this.props.data.files.map((slideImage, index) => (
              <img
                className="ads-img-slider-mobile"
                alt="slider images"
                src={endpoint + slideImage}
              />
            ))}
          </Slide>
          <Row>
            <Col sm={6} xs={6} className="adID-mobile">
              <p>Listing ID: #{this.props.data.adId}</p>
            </Col>
            <Col sm={2} xs={2}>
              <BsFillShareFill size={19} className="ads-call-icons" onClick={() => this.shareModal(this.props.data.adId)} />
            </Col>
            <Col sm={2} xs={2}>
              <FiMapPin
                size={23}
                className="ads-call-icons"
                onClick={() =>
                  this.openLocation(this.props.data.lat, this.props.data.lng)
                }
              />
            </Col>
            <Col sm={2} xs={2}>
              <a href={"tel:" + "+91" + this.props.data.contactNumber}>
                <BsTelephone size={23} className="ads-call-icons" />
              </a>
            </Col>
          </Row>
          <div className="reduce-padding dk boxes-3-margin">
            <Row>
              <Col className="border-class" md={4} xs={4} sm={4}>
                <p className="pp1Txt_mobile">Type</p>
                <p className="pptxt-pp1-mobile">
                  {this.props.data.typeProperty}
                </p>
              </Col>
              <Col className="border-class" md={4} xs={4} sm={4}>
                <p className="pp1Txt_mobile">Size</p>
                <p className="pptxt-pp1">{this.props.data.areaInCity}</p>
              </Col>
              <Col md={4} xs={4} sm={4}>
                <p className="pp1Txt_mobile2">Price</p>
                <p className="pptxt-pp1">{this.props.data.startingPrice}</p>
              </Col>
            </Row>
          </div>
          <p className="propadd-txt">{this.props.data.propertyAddress}</p>
          <p className="landmark-txt">
            <FiMapPin
              size={20}
              className="landmark-icon"
              onClick={() =>
                this.openLocation(this.props.data.lat, this.props.data.lng)
              }
            />
            {this.props.data.landmark}
          </p>
          <center>
            {this.props.data.viewVirtualLink ? (
              <button
                className="brocher-btn-virtual_new"
                onClick={() =>
                  this.handleVirtual(this.props.data.viewVirtualLink)
                }
              >
                Virtual Tour
              </button>
            ) : (
              <></>
            )}
            <iframe
              style={{ marginTop: 20 }}
              src={this.props.data.youtubeLink[0]}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="adVideo-mobile"
            ></iframe>
          </center>
          <Row>
            <Col md={6} sm={6} xs={6}>
              <button
                className="brocher-btn-virtual_new"
                onClick={() => this.handleDetails(this.props.data)}
              >
                <span>Details</span>
              </button>
            </Col>
            <Col md={6} sm={6} xs={6}>
              {this.props.data.brochure ? (
                <button
                  className="brocher-btn-virtual_new_blue"
                  onClick={() => this.brochure(this.props.data.brochure)}
                >
                  <span>Brochure</span>
                </button>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withRouter(AdsCard);
