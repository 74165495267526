import React, {useState, useEffect} from 'react'
import { GoogleMap, useJsApiLoader, Marker, InfoWindow, } from '@react-google-maps/api';

const containerStyle = {
  width: '88%',
  height: '90vh',
  marginLeft: "6%",
  marginTop: "3%",
  borderRadius: 10
};


const mapOptions = {
  zoom: 10,
  styles: [
    {
      featureType: 'poi',
      stylers: [{ visibility: 'off' }],
    },
  ],
};


const customIcon = {
  url: "https://i.ibb.co/7v877vm/marker.png"
};

function MapComponent(locationProps) {
  //const { locationProps } = props;
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyBttNovQQfTde-VkUHOugPWUrgr9DkmmaU"
  })

  const [mapRef, setMapRef] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [map, setMap] = React.useState(null);
  const [infoWindowData, setInfoWindowData] = useState();
  const [markers, setMarkers] = useState(locationProps);
  const [position, setPosition] = useState({
    lat: 17.6868, 
    lng: 83.2185
  });

  useEffect(() => {
    let obj = {
      lat: markers.locationProps[0].lat,
      lng: markers.locationProps[0].lng,
    }
    setPosition(obj)
    setMarkers(markers.locationProps)
  }, []);

  const onLoad = React.useCallback(function callback(map) {
    let obj = {
      lat: markers.locationProps[0].lat,
      lng: markers.locationProps[0].lng,
    }
    const bounds = new window.google.maps.LatLngBounds(obj);
    map.fitBounds(bounds);
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])
  
  const handleMarkerClick = (id, lat, lng, adTitle) => {
    mapRef?.panTo({ lat, lng });
    setInfoWindowData({ id, adTitle });
    setIsOpen(true);
  };

  return isLoaded ? (
    <>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={position}
        options={mapOptions}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <>
          {markers.map(({ adTitle, lat, lng }, key) => (
            <>
              {
                markers.length > 0 ?
                <>
                <Marker
                    key={key}
                    position={{ lat, lng }}
                    icon={customIcon}
                    onClick={() => {
                      handleMarkerClick(key, lat, lng, adTitle);
                    }}
                  >
                    {isOpen && infoWindowData?.id === key && (
                      <InfoWindow
                        options= {{minWidth : 200 }}
                        onCloseClick={() => {
                          setIsOpen(false);
                        }}
                      >
                        <p className='markerTxt' onClick={() => window.open("https://myplotpic.com/details?ad=00056", "_blank")}>{infoWindowData.adTitle}</p>
                      </InfoWindow>
                    )}
                  </Marker>
                </>
                :
                <></>
              }
            </>
          ))}
        </>
      </GoogleMap>
    </>
    
  ) : <></>
}

export default React.memo(MapComponent)